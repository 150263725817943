import React from 'react';
import Loader from 'components/commons/_/loader/Loader';
import ProcessList from 'components/employeePortal/process/ProcessList';
import Contacts from 'components/employeePortal/process/community/Contacts';
import ActionList from 'components/employeePortal/process/action/ActionList';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import UserService from 'services/user/UserService';
import { wire } from 'react-hot-wire';
import EmployeeProgress from 'components/employeePortal/process/employee/EmployeeProgress';
import classnames from 'classnames';
import useEmployeePortalContext from './context/employeePortalContext';
import { Button } from 'components/_/common/button/Button';
import ProcessService from 'services/onboarding/process/ProcessService';
import { useMutation } from 'react-query';
import FilterByEmployee from 'components/employeePortal/process/filter/FilterByEmployee';

export type PortalDesktopProps = InjectedIntlProps & {};

export type PortalDesktopWiredProps = PortalDesktopProps & {
	'services.userService': UserService;
	'services.onboardingProcessService': ProcessService;
};

const PortalDesktop = ({
	intl,
	'services.userService': userService,
	'services.onboardingProcessService': processService,
}: PortalDesktopWiredProps) => {
	const {
		progress: {
			data: { items: processList },
		},
		employeeProgress: {
			data: { items: employeeProcessList },
		},
		actions: { isFetching: actionListLoading },
		contact: {
			data: { items: contactList },
		},
		filterByUser,
	} = useEmployeePortalContext();
	const processReport = useMutation(() => processService.fetchReport('process-report', filterByUser?.id));
	const processActionsReport = useMutation(() => processService.fetchReport('process-actions', filterByUser?.id));
	const userProcessList = processList.filter(process => userService.user()?.userProfile.id() === process.userId);

	return (
		<div className="d-flex flex-column flex-sm-row flex-row portal__content">
			<div className="portal__process mr-sm-3">
				<div className="mt-6 mb-5 fs-6 font-weight-bold d-flex flex-row align--center">
					{intl.formatMessage({ id: 'portal.title' }, { name: userService.user()?.userProfile.forename() })}
					<div className="flex-shrink-1 ml-auto" style={{ minWidth: '15.625rem' }}>
						<FilterByEmployee />
					</div>
				</div>
				{!!userProcessList.length && (
					<>
						<ProcessList processList={userProcessList} expandable />
						<div className="portal__spacer my-5" />
					</>
				)}
				{actionListLoading && (
					<div className="d-flex justify--center">
						<Loader fixed={false} />
					</div>
				)}
				<ActionList />
			</div>
			<div className="portal__additional ml-sm-3">
				{!!employeeProcessList.length && (
					<div>
						<div className="mt-6 mb-4 d-flex align-items-center justify-content-between">
							<p className="fs-6 font-weight-bold">
								{intl.formatMessage({ id: 'portal.employees.yours' })}
							</p>
							<div className="d-flex align-items-center fs-4">
								<span className="portal__download-report mr-2">
									{intl.formatMessage({ id: 'portal.process.report.downloadReport' })}
								</span>
								<Button
									className="download-report__button py-3 d-flex align-items-center"
									disabled={processReport.isLoading}
									isLoading={processReport.isLoading}
									onClick={() => processReport.mutate()}
								>
									<i className="fa fa-download mr-2" />
									<span>
										{intl.formatMessage({ id: 'portal.process.report.button.processReport' })}
									</span>
								</Button>
								<Button
									className="download-report__button py-3 d-flex align-items-center ml-2"
									disabled={processActionsReport.isLoading}
									isLoading={processActionsReport.isLoading}
									onClick={() => processActionsReport.mutate()}
								>
									<i className="fa fa-download mr-2" />
									<span>
										{intl.formatMessage({
											id: 'portal.process.report.button.processActionsReport',
										})}
									</span>
								</Button>
							</div>
						</div>
						<EmployeeProgress processList={employeeProcessList} />
					</div>
				)}
				{!!contactList.length && (
					<div>
						<p
							className={classnames(
								'fs-6 font-weight-bold',
								employeeProcessList.length ? 'mt-5 mb-5' : 'mt-6 mb-5'
							)}
						>
							{intl.formatMessage({ id: 'portal.contacts.title' })}
						</p>
						<Contacts contactList={contactList} />
					</div>
				)}
			</div>
		</div>
	);
};

export default injectIntl(
	wire<PortalDesktopWiredProps, PortalDesktopProps>(
		['services.userService', 'services.onboardingProcessService'],
		PortalDesktop
	)
);
