import APIService, { ContentType } from 'services/APIService';
import Process, { ProcessDTO } from 'models/onboarding/process/Process';

export type AssignRoleParams = { processId: string; roleId: string; userId: string };

export default class ProcessService {
	constructor(private readonly ApiService: APIService) {}

	fetchList() {
		return this.ApiService.get<{ items: ProcessDTO[] }>(`/v1/workflows/process/progress`).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	fetchEmployeeList(employeeId?: string) {
		let url = '/v1/workflows/my-users/process/progress?decorate=user,custom-role';

		if (employeeId) {
			url += `&employeeId=${employeeId}`;
		}

		return this.ApiService.get<{ items: ProcessDTO[] }>(url).then(response => ({
			items: response.items.map(process => this.toModel(process)),
		}));
	}

	async fetchReport(type: 'process-report' | 'process-actions', employeeId?: string) {
		let url = `/v1/workflows/report/${type}`;

		if (employeeId) {
			url += `?employeeId=${employeeId}`;
		}

		const data = await this.ApiService.get(url, null, {
			responseType: 'arraybuffer',
		});

		const file = new Blob([data], {
			type: ContentType.XLSX,
		});

		window.open(window.URL.createObjectURL(file));
	}

	async assignRole({ processId, roleId, userId }: AssignRoleParams) {
		await this.ApiService.post(`/v1/workflows/process/${processId}/role/${roleId}`, { user_id: userId });

		return true;
	}

	toModel(process: ProcessDTO): Process {
		return new Process(process);
	}
}
