import wrapperMenu from 'components/wrapper/wrapperMenu';
import wrapperMissionList from 'components/wrapper/wrapperMissionList';
import SwipeMenu from 'components/wrapper/SwipeMenu';

export default {
	'components.base.WrapperMenu': {
		factory: wrapperMenu,
		deps: [
			{ $ref: 'components.base.SwipeMenu' },
			{ $ref: 'services.applicationService' },
			{ $ref: 'services.browserHistoryService' },
		],
	},
	'components.WrapperMissionList': {
		factory: wrapperMissionList,
		deps: [{ $ref: 'services.taskService' }],
	},
	'components.base.SwipeMenu': {
		factory: () => SwipeMenu,
	},
};
