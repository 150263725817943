import React, { useEffect, useMemo, useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import InputSearch, { InputSearchProps } from 'components/_/form/input-search/input-search';
import useEmployeePortalContext from '../../context/employeePortalContext';

export type FilterByEmployeeProps = InjectedIntlProps & {
	onSelect?: InputSearchProps['onSelect'];
};

const FilterByEmployee = ({ intl, onSelect }: FilterByEmployeeProps) => {
	const { employeeList, setFilterByUser } = useEmployeePortalContext();
	const [userList, setUserList] = useState<{ id: string; name: string }[]>([]);
	const [inputValue, setInputValue] = useState('');
	const [search, setSearch] = useState('');
	const items = useMemo(
		() =>
			userList
				.map(user => ({
					value: user.id,
					content: user.name,
				}))
				.filter(item => (search ? item.content.includes(search) : true)),
		[userList, search]
	);

	useEffect(() => {
		setUserList([
			{
				id: '',
				name: intl.formatMessage({ id: 'portal.process.filter-all' }),
			},
			...employeeList.map(employee => ({
				id: employee.id,
				name: employee.fullName,
			})),
		]);
	}, [employeeList]);

	if (userList.length < 3) {
		return null;
	}

	return (
		<InputSearch
			placeholder={intl.formatMessage({ id: 'portal.process.filter-all' })}
			items={items}
			search={inputValue}
			setSearch={value => {
				setInputValue(value);
				setSearch(value);
			}}
			onSelect={(value, item) => {
				setFilterByUser({
					id: value as string,
					name: item?.content || '',
				});
				setSearch('');
				onSelect && onSelect(value, item);
			}}
		/>
	);
};

export default injectIntl(FilterByEmployee);
