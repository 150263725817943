import React, { PureComponent } from 'react';
import { wire } from 'react-hot-wire';
import _ from 'lodash';

import UserProfile from './UserProfile.template';
import Loader from 'components/commons/_/loader/Loader';
import { MappedUserProfileFieldDefinition } from 'models/user/userProfileField/definition/MappedUserProfileFieldDefinition';

export class UserProfileComponent extends PureComponent {
	state = {
		agreementsPopup: false,
	};

	componentDidMount() {
		this._fetchProfileDetails(this.props['services.userProfileService']);
	}

	render() {
		const Currencies = this.props['components.Currencies'];
		const UserAgreements = this.props['profile.user.UserAgreements'];
		const Avatar = this.props['components.Avatar'];
		const levelService = this.props['services.levelService'];
		const applicationService = this.props['services.applicationService'];
		const { level, wallet } = this.state;

		const avatarField = _.find(
			this.state.fields,
			userProfileField =>
				userProfileField.definition() instanceof MappedUserProfileFieldDefinition &&
				userProfileField.definition().mappedProfileField() === 'avatar'
		);

		if (_.isEmpty(this.state.wallet)) {
			return <Loader />;
		}

		return (
			<UserProfile
				{...this.state}
				params={this.props.match.params}
				level={levelService.level(level, wallet)}
				exp={wallet.getCurrencyByName('exp')}
				Currencies={Currencies}
				UserAgreements={UserAgreements}
				Avatar={Avatar}
				avatarFieldDefinition={avatarField ? avatarField.definition() : null}
				onFieldChange={(name, value) => this._onFieldChange(name, value)}
				onAvatarChange={avatar => this._onAvatarChange({ avatar })}
				onCloseAgreementsPopup={() => this.setState({ agreementsPopup: false })}
				openAgreementsPopup={() => this.setState({ agreementsPopup: true })}
				hasAgreements={applicationService.settings().hasAgreements()}
			/>
		);
	}

	async _fetchProfileDetails(profileService) {
		const { id } = this.props.match.params;
		const notificationAddress = id ? `/user/${id}/notifications` : '/user/notifications';
		const navigationService = this.props['services.navigationService'];
		let details = null;

		if (!id && profileService.isAnonymous()) {
			navigationService.replace(notificationAddress);

			details = {
				...profileService.user(),
				userProfile: profileService.toModel({
					forename: 'Anonymous',
				}),
			};
		} else {
			details = await profileService.fetchDetails({
				id,
			});
		}

		if (!details.fields) {
			navigationService.replace(notificationAddress);
		}

		this.setState(details);
	}

	_onFieldChange(name, value) {
		const { userProfile } = this.state;

		userProfile.setField(name, value);

		this.forceUpdate();
	}

	_onAvatarChange({ avatar }) {
		const userProfileService = this.props['services.userProfileService'];

		userProfileService.updateAvatar({
			avatar,
		});
	}
}

export default wire(
	[
		'components.Currencies',
		'profile.user.UserAgreements',
		'components.Avatar',
		'services.userProfileService',
		'services.applicationService',
		'services.navigationService',
		'services.levelService',
	],
	UserProfileComponent
);
