import { find, isEmpty } from 'lodash';

export default class LevelService {
	constructor(applicationService) {
		this._applicationService = applicationService;
	}

	level(currentLevel, wallet) {
		const exp = wallet.getCurrencyByName('exp');
		const expAmount = exp ? exp.value() : 0;
		const levels = this._applicationService.applicationConfig().levels();
		const nextLevel = find(levels, level => level.minPoints() > expAmount);

		if (isEmpty(levels)) {
			return {
				current: null,
				progress: {
					percentage: 0,
				},
			};
		}

		if (!nextLevel) {
			return {
				current: currentLevel,
				progress: {
					percentage: 100,
				},
			};
		}

		if (!currentLevel) {
			return {
				current: null,
				next: nextLevel,
				missingExp: nextLevel.minPoints() - expAmount,
				progress: {
					current: expAmount,
					max: nextLevel.minPoints(),
					percentage: Math.round((expAmount / nextLevel.minPoints()) * 100),
				},
			};
		}

		const current = expAmount - currentLevel.minPoints();
		const max = nextLevel.minPoints() - currentLevel.minPoints();

		return {
			current: currentLevel,
			next: nextLevel,
			missingExp: nextLevel.minPoints() - expAmount,
			progress: {
				current,
				max,
				percentage: Math.round((current / max) * 100),
			},
		};
	}
}
