import AssignRoleAction from 'models/onboarding/process/action/AssignRoleAction';
import React, { useMemo, useState } from 'react';
import InputSearch from 'components/_/form/input-search/input-search';
import { Button } from 'components/_/common/button/Button';
import EmployeeService from 'services/onboarding/employee/EmployeeService';
import { wire } from 'react-hot-wire';
import { useDebounce } from 'use-debounce/esm';
import { useMutation, useQuery } from 'react-query';
import EmployeePreview from 'models/onboarding/employee/EmployeePreview';
import Role from 'models/onboarding/role/Role';
import Employee from 'models/onboarding/employee/Employee';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import ProcessService, { AssignRoleParams } from 'services/onboarding/process/ProcessService';
import images from 'assets/images';
import classnames from 'classnames';

export type AssignRoleActionViewProps = InjectedIntlProps & {
	action: AssignRoleAction;
	refetchActions: () => void;
	roles: Role[];
	zIndex: number;
};

export type AssignRoleActionViewWiredProps = AssignRoleActionViewProps & {
	'services.onboardingEmployeeService': EmployeeService;
	'services.onboardingProcessService': ProcessService;
};

const AssignRoleActionView = ({
	action,
	refetchActions,
	roles,
	zIndex,
	intl,
	'services.onboardingEmployeeService': employeeService,
	'services.onboardingProcessService': processService,
}: AssignRoleActionViewWiredProps) => {
	const [user, setUser] = useState<{ id: string; name: string } | undefined>();
	const [search, setSearch] = useState('');
	const [debouncedSearch] = useDebounce(search, 1200);
	const role = useMemo(() => roles.find(role => role.id === action.roleId), [roles]);
	const employeeQuery = useQuery<{ items: EmployeePreview[] }>(['employee-preview', debouncedSearch, role], () =>
		employeeService.fetchEmployeePreviewList({
			search: debouncedSearch,
			skipIds: role?.users.map(user => user.userId),
		})
	);
	const { mutate, isLoading, isSuccess } = useMutation((data: AssignRoleParams) => processService.assignRole(data), {
		onSuccess: () => refetchActions(),
	});
	const MobileSubmitIcon = images.portal.check;

	const employeeItems = useMemo(() => {
		const employees: (EmployeePreview | Employee)[] = [];

		if (role) {
			employees.push(...role.users.filter(user => new RegExp(`.*${debouncedSearch}.*`, 'i').test(user.fullName)));
		}

		employees.push(...(employeeQuery.data?.items || []));

		return employees.slice(0, 10).map(employee => ({
			content: employee.fullName,
			value: employee.userId,
		}));
	}, [employeeQuery.data, role, debouncedSearch, user]);

	return (
		<div className="d-flex row mb-2 align-items-center flex-row flex-nowrap">
			<div className="flex-grow-1 mr-3 mr-sm-4 ml-2" style={{ zIndex }}>
				<InputSearch
					placeholder={intl.formatMessage(
						{ id: 'portal.action.assign-role.placeholder' },
						{ roleName: action.roleName }
					)}
					items={employeeItems}
					search={search}
					setSearch={setSearch}
					onSelect={(value, item) => {
						setUser({
							id: value as string,
							name: item?.content || '',
						});
					}}
					disabled={isLoading || isSuccess}
				/>
			</div>
			<Button
				style={{ zIndex }}
				color={!user || isSuccess ? 'disabled' : 'interactive'}
				className="fs-5 mr-2 d-none d-sm-block"
				disabled={isLoading || !user || isSuccess}
				isLoading={isLoading}
				onClick={() =>
					user &&
					!isSuccess &&
					mutate({
						processId: action.processId,
						roleId: action.roleId,
						userId: user.id,
					})
				}
			>
				{intl.formatMessage({ id: 'portal.action.assign-role.confirm' })}
			</Button>
			<div
				className={classnames(
					'd-sm-none position-relative action__assignRole__mobileSubmit rounded-circle d-flex align-items-center justify--center flex-shrink-0 mr-2',
					{
						'action__assignRole__mobileSubmit--disabled': !user || isSuccess,
					}
				)}
				onClick={() =>
					user &&
					!isSuccess &&
					mutate({
						processId: action.processId,
						roleId: action.roleId,
						userId: user.id,
					})
				}
			>
				{!isLoading && <MobileSubmitIcon className="icon" size="1.5rem" />}
				{isLoading && (
					<div
						className={classnames('fa', {
							'action__assignRole__mobileSubmit--loading': isLoading || isSuccess,
						})}
					/>
				)}
			</div>
		</div>
	);
};

export default injectIntl(
	wire<AssignRoleActionViewWiredProps, AssignRoleActionViewProps>(
		['services.onboardingEmployeeService', 'services.onboardingProcessService'],
		AssignRoleActionView
	)
);
