import _ from 'lodash';
import { Service } from 'react-hot-wire';

import UserProfile from 'models/user/UserProfile';
import Level from 'models/user/Level';
import Membership from 'models/user/Membership';

export default class UserProfileService extends Service {
	constructor(APIService, applicationService, currencyService, userProfileFieldService) {
		super();

		this.APIService = APIService;

		this._applicationService = applicationService;
		this._currencyService = currencyService;
		this._userProfileFieldService = userProfileFieldService;
		//TODO: create Wallet class with wallet and wallet queue
		this._user = null;
	}

	user() {
		return this._user;
	}

	isAnonymous() {
		return !this._user.userProfile;
	}

	toModel(userProfile) {
		return new UserProfile(userProfile);
	}

	/**
	 *
	 * @param {string|number} id
	 * @returns {Promise}
	 */
	fetchDetails({ id } = {}) {
		const url = 'v1/user-profile';

		return this.APIService.get(id ? `${url}/${id}` : url).then(
			({ canEdit, fields, level, membership, userProfile, wallet }) => {
				let result = {
					canEdit,
					fields: fields && this._userProfileFieldService.toModel(fields),
					level: level && new Level(level),
					membership: membership && new Membership(membership),
					userProfile: userProfile && this.toModel(userProfile),
					wallet:
						wallet &&
						this._currencyService.toModel(wallet, {
							allowZeroValues: true,
						}),
					walletQueue: [],
				};

				if (!id) {
					this._user = result;
				}

				return result;
			}
		);
	}

	/**
	 * @param {Object} avatar
	 * @param {string} nickname
	 * @param {string} description
	 * @param {string} surname
	 * @param {string} forename
	 * @returns {Promise}
	 */
	editProfile({ avatar, nickname, description, surname, forename } = {}) {
		const data = new FormData();

		if (avatar) {
			data.append('avatar[file]', avatar.file);

			if (avatar.delete) {
				data.append('avatar[delete]', avatar.delete);
			}
		}

		if (nickname) {
			data.append('nickname', nickname);
		}

		if (description) {
			data.append('description', description);
		}

		if (surname) {
			data.append('surname', surname);
		}

		if (forename) {
			data.append('forename', forename);
		}

		return this.APIService.post('v1/user-profile/edit', data);
	}

	editUserProfileField({ field, value }) {
		return this.APIService.post('v1/user-profile/fields', {
			fields: [
				{
					definition: {
						id: field.definition().id(),
					},
					value,
				},
			],
		});
	}

	updateAvatar({ avatar }) {
		const data = new FormData();

		data.append('avatar[file]', avatar.blob);
		//data.append('avatar[delete]', 1);

		data.append('_method', 'put');

		return this.APIService.post('v1/user-profile/avatar', data).then(() => {
			this._user.userProfile.setAvatar(avatar.image);
		});
	}

	fetchContainerFromUserContainerGroup({ id }) {
		return this.APIService.get(`v1/container-group/user/${id}/container`);
	}

	addToWalletQueue(points) {
		this._user.walletQueue.push(points);

		this.runChangeListeners();
	}

	newPoints() {
		const queue = this._user.walletQueue.slice();
		const points = this._currencyService.merge({ allowZeroValues: false }, ...queue);

		this._user.walletQueue = [];

		if (!_.isEmpty(queue)) {
			// update wallet points after animation time (1s)
			setTimeout(() => this.addToWallet(points), 1000);
		}

		return points;
	}

	addToWallet(points) {
		this._user.wallet = this._currencyService.merge(null, this._user.wallet, points);

		this._updateLevel();

		this.runChangeListeners();
	}

	_updateLevel() {
		const level = _.findLast(
			this._applicationService.applicationConfig().levels(),
			_level => _level.minPoints() <= this._user.wallet.getCurrencyByName('exp').value()
		);

		this._user.level = level ? level : null;
	}
}
