import * as Yup from 'yup';
import { isPostalCode, isMobilePhone } from 'validator';

Yup.setLocale({
	mixed: {
		required: () => ({ message: 'required' }),
	},
	string: {
		min: ({ min }) => ({ message: 'too-short', values: { min } }),
		max: ({ max }) => ({ message: 'too-long', values: { max } }),
		email: () => ({ message: 'invalid-email' }),
		url: () => ({ message: 'invalid-url' }),
	},
	array: {
		min: ({ min }) => ({ message: 'min-elements', values: { min } }),
	},
});

Yup.addMethod(Yup.string, 'postalCode', function() {
	return this.test({
		name: 'postal-code',
		message: { message: 'invalid-postal-code' },
		test: value => (value ? isPostalCode(value, 'PL') : true),
	});
});

Yup.addMethod(Yup.string, 'mobilePhone', function() {
	return this.test({
		name: 'mobile-phone',
		message: { message: 'invalid-mobile-phone' },
		test: value => (value ? isMobilePhone(value, 'pl-PL') : true),
	});
});
