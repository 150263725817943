import APIService from 'services/APIService';
import Role, { RoleDTO } from 'models/onboarding/role/Role';

export type GetRoleParameters = {
	roleId: string;
};

export default class RoleService {
	constructor(private readonly ApiService: APIService) {}

	fetchRole({ roleId }: GetRoleParameters) {
		return this.ApiService.get<RoleDTO>(
			`/v1/workflows/role/${roleId}?decorate=user&collection_key=users`
		).then(response => this.toModel(response));
	}

	toModel(role: RoleDTO): Role {
		return new Role(role);
	}
}
