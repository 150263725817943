import React from 'react';
import { wire } from 'react-hot-wire';
import { createGlobalStyle } from 'styled-components';

// alwyn new rounded fonts:
import AlwynNewRoundedThin from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Thin.woff';
import AlwynNewRoundedRegular from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Regular.woff';
import AlwynNewRoundedRegularItalic from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Regular_Italic.woff';
import AlwynNewRoundedMedium from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Medium.woff';
import AlwynNewRoundedBold from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Bold.woff';

// klavika fonts:
import KlavikaThin from 'assets/fonts/klavika/Klavika_Light.woff';
import KlavikaRegular from 'assets/fonts/klavika/Klavika_Regular.woff';
import KlavikaRegularItalic from 'assets/fonts/klavika/Klavika_Regular_Italic.woff';
import KlavikaMedium from 'assets/fonts/klavika/Klavika_Medium.woff';
import KlavikaBold from 'assets/fonts/klavika/Klavika_Bold.woff';

function Fonts(props) {
	const applicationService = props['services.applicationService'];

	if (!applicationService) {
		return null;
	}

	const font = applicationService.settings().font();

	const fonts = {
		klavika: {
			thin: KlavikaThin,
			regular: KlavikaRegular,
			regularItalic: KlavikaRegularItalic,
			medium: KlavikaMedium,
			bold: KlavikaBold,
		},
		alwyn_new_rounded: {
			thin: AlwynNewRoundedThin,
			regular: AlwynNewRoundedRegular,
			regularItalic: AlwynNewRoundedRegularItalic,
			medium: AlwynNewRoundedMedium,
			bold: AlwynNewRoundedBold,
		},
	};

	const fontFace = ({ font, fontWeight, customStyles }) => `
		@font-face {
			font-family: current-font;
			src: url('${font}') format('woff');
			font-weight: ${fontWeight};
			${customStyles}
		}
	`;

	const GlobalFonts = createGlobalStyle`
		${fontFace({ font: fonts[font].thin, fontWeight: 100 })}
		${fontFace({ font: fonts[font].regular, fontWeight: 400 })}
		${fontFace({
			font: fonts[font].regularItalic,
			fontWeight: 400,
			customStyles: `
				font-style: italic;
			`,
		})}
		${fontFace({ font: fonts[font].medium, fontWeight: 600 })}
		${fontFace({ font: fonts[font].bold, fontWeight: 800 })}
	`;

	return <GlobalFonts />;
}

export default wire(['services.applicationService'], Fonts);
