import React from 'react';
import classnames from 'classnames';
import {injectIntl} from "react-intl";

import useStyles from './Select.jss';

export const Select = injectIntl(({ intl, className, list, ...props }) => {
	const classes = useStyles()();

	return (
		<label className={classnames(classes.select, className)}>
			<select
				{...props}
				// performance issue:
				onMouseOver={() => {}}
			>
				{[
					{
						value: '',
						content: intl.formatMessage({ id: 'common.form.block.dropdownDefault' })
					},
					...list
				].map(option => (
					<option key={option.value} value={option.value}>
						{option.content}
					</option>
				))}
			</select>
			<div className={classes.arrow} />
		</label>
	);
});
