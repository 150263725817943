import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import images from 'assets/images';
import SVGImage from 'components/commons/SVGImage';

const Currency = ({ currency }) => {
	return currency ? (
		<div
			className={classnames('animation', {
				positive: currency.value() > 0,
				negative: currency.value() < 0,
			})}
		>
			<div className="currency d-flex ml-3 align-items-center">
				<SVGImage src={currency.icon() || images.currency[currency.codeName()]} className="icon mr-1" />
				<p className="value fs-mobile fs-sm-5 font-weight-bolder">{currency.value()}</p>
			</div>
		</div>
	) : null;
};

export default ({ wallet, newPoints }) => (
	<div className="currencies d-flex align-items-center pl-2 pl-sm-3 pr-4 pr-sm-5">
		{wallet.values().map(currency => (
			<div key={currency.codeName()}>
				<div className="currency d-flex ml-3 align-items-center">
					<SVGImage src={currency.icon() || images.currency[currency.codeName()]} className="icon mr-1" />
					<p className="value fs-mobile fs-sm-5 font-weight-bolder">{currency.value()}</p>
				</div>
				{_.isEmpty(newPoints) ? null : <Currency currency={newPoints.getCurrencyByName(currency.codeName())} />}
			</div>
		))}
	</div>
);
